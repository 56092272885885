
























export default {
  name: "Offers",
  props: {
    jobs: {}
  }
};
