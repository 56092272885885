var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"perks"},[_c('div',{staticClass:"current"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.prev),expression:"prev",arg:"swipe",modifiers:{"right":true}},{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.next),expression:"next",arg:"swipe",modifiers:{"left":true}}],staticClass:"selectedPerk"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{key:_vm.selectedPerk},[_c('h3',[_vm._v(_vm._s(_vm.perks[_vm.selectedPerk].title))]),_c('div',{staticClass:"image",style:(_vm.perks[_vm.selectedPerk].image &&
                'background-image: url(' +
                  require('@/assets/images/career/perks/' +
                    _vm.perks[_vm.selectedPerk].image) +
                  ');')}),_c('span',[_vm._v(_vm._s(_vm.perks[_vm.selectedPerk].desc))])])]),_c('div',{staticClass:"arrow prev",on:{"click":function($event){return _vm.prev()}}}),_c('div',{staticClass:"arrow next",on:{"click":function($event){return _vm.next()}}})],1)]),_c('div',{staticClass:"info"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("career.perksSection.headline")))]),_c('span',[_vm._v(_vm._s(_vm.$t("career.perksSection.text")))]),_c('ul',{staticClass:"perksList"},_vm._l((_vm.perks),function(perk,index){return _c('li',{key:index,class:_vm.selectedPerk === index && 'selected',style:(perk.image &&
              'background-image: url(' +
                require('@/assets/images/career/perks/' + perk.image) +
                ');'),on:{"click":function($event){_vm.selectedPerk = index}}},[_vm._v(" "+_vm._s(perk.title)+" ")])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }