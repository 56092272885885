





















































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Perks"
})
export default class Perks extends Vue {
  perks = this.$store.state.base.career.perksSection.perks;
  selectedPerk = 0;

  next() {
    if (this.selectedPerk + 1 < this.perks.length) this.selectedPerk++;
    else this.selectedPerk = 0;
  }

  prev() {
    if (this.selectedPerk - 1 >= 0) this.selectedPerk--;
    else this.selectedPerk = this.perks.length - 1;
  }
}
