























import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import TextComponent from "@/components/TextComponent.vue";
import Offers from "@/components/Career/Career.Offers.vue";
import WhyWorkWithUs from "@/components/Career/Career.WhyWorkWithUs.vue";
import Perks from "@/components/Career/Career.Perks.vue";
import TakeALook from "@/components/Career/Career.TakeALook.vue";

@Component({
  name: "Career",
  components: { TakeALook, Perks, WhyWorkWithUs, Offers, TextComponent }
})
export default class Career extends Vue {
  jobs = [];
  mounted() {
    axios.get(this.$store.state.apiUrl + "/work-offers").then(response => {
      if (response.status === 200) this.jobs = response.data;
      else console.log(response.statusText);
    });
  }
}
